import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Avatar } from '@mui/material';
import { motion } from 'framer-motion';

import Lawrence from "../../images/team/Lawrence.png";
import Connor from "../../images/team/Connor.png";
import Alexandria from "../../images/team/Alexandria.jpg"

import Syntyche from "../../images/team/Syntyche.png";
import Catherine from "../../images/team/Catherine.png";
import Pooja from "../../images/team/Pooja.png";

import Mark from "../../images/team/Mark.jpg";
import Rui from "../../images/team/Rui.jpg";


import Ty from "../../images/team/Ty.jpg";
import Tyler from "../../images/team/Tyler.jpg";
import Mansi from "../../images/team/Mansi.jpg";
import Yamini from "../../images/team/Yamini.jpeg";
import Henry from "../../images/team/Henry.jpg";
import Asher from "../../images/team/Asher.jpg";
import Corey from "../../images/team/Corey.jpg";
import Summer from "../../images/team/Summer.jpg";
import Johnny from "../../images/team/Johny.jpg";
import Anastasiya from "../../images/team/Anastasiya.jpg";
import Laura from "../../images/team/Laura.png";
import Daniel from "../../images/team/Booth.jpg";
import Myreille from "../../images/team/Myreille.png"; 
import Mitesh from "../../images/team/Mitesh.png";

const TeamMember = ({ name, role, image, linkedin, delay }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      whileHover={{ scale: 1.05 }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          mb: 2,
          cursor: 'pointer',
          '&:hover .role': {
            color: '#52D5F2', // Change to desired color on hover
            fontWeight:"bold",
          }
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => linkedin && window.open(linkedin, '_blank')}
      >
        <Box 
          sx={{ 
            position: 'relative', 
            mb: 1,
          }}
        >
          <motion.div
            animate={{ scale: isHovered ? 1.1 : 1 }}
            transition={{ duration: 0.3 }}
          >
            <Avatar 
              src={image} 
              sx={{ 
                width: 120, 
                height: 120,
                filter: isHovered ? 'grayscale(0%)' : 'grayscale(100%)',
                transition: 'filter 0.3s ease',
                background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                zIndex: 1,
              }}
            >
              {!image && name[0]}
            </Avatar>
          </motion.div>
        </Box>
        <Typography variant="body1" align="center" sx={{ color: 'white' }}>{name}</Typography>
        <Typography className="role" variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} align="center">{role}</Typography>
      </Box>
    </motion.div>
  );
};



const executiveTeam = [
  { name: "Lawrence Bugg", role: "CEO", linkedin:"https://www.linkedin.com/in/lawrencebugg/", image:Lawrence },
  { name: "Connor Brennan", role: "CTO", linkedin:"https://www.linkedin.com/in/connor-brennan-13813736/",image:Connor  },
  { name: "Alexandria(Ali) Lin", role: "Lead Game Dev", linkedin:"https://www.linkedin.com/in/alexandria-lin-5893a919b/",image:Alexandria },
];

const leadershipTeam = [];

const advisors = [
  { name: "Mark Braatz", role: "Games Marketing Advisor", linkedin:"https://www.linkedin.com/in/mark-braatz-2111533/", image:Mark },
  { name: "Rui Pei", role: "Social Interaction Advisor", linkedin:"https://www.linkedin.com/in/ruipei/", image:Rui },
];

const contributors = [
  { name: "Syntyche Jennings-Bechar", role: "Head of Gamified Psychometrics", linkedin:"https://www.linkedin.com/in/syntyche-jennings/", image:Syntyche },
  { name: "Catherine Stover", role: "Curriculum Development", linkedin:"https://www.linkedin.com/in/cswinter/", image:Catherine },
  { name: "Pooja Wagh", role: "Head of UI Design and Dev", linkedin:"https://www.linkedin.com/in/poojawagh/", image:Pooja },
  { name: "Ty Victorson", role: "Game Developer", linkedin:"https://www.linkedin.com/in/ty-victorson/", image:Ty },
  { name: "Tyler Sloss", role: "Game Developer", linkedin:"https://www.linkedin.com/in/tyler-sloss/", image:Tyler },
  { name: "Mansi Vyas", role: "AI/ML Engineer", linkedin:"https://www.linkedin.com/in/mansivyas261/", image:Mansi },
  { name: "Yamini Muthyala", role: "Data Scientist", linkedin:"https://www.linkedin.com/in/yaminimuthyala/", image:Yamini },
  { name: "Henry Ma", role: "Character Artist", linkedin:"https://www.linkedin.com/in/henrytinma/", image:Henry },
  { name: "Asher Chase", role: "Game Producer", linkedin:"https://www.linkedin.com/in/asherchase/", image:Asher }, 
  { name: "Corey Staier", role: "Narrative Designer", linkedin:"https://www.linkedin.com/in/cstaier/", image:Corey },
  { name: "Summer Gadsby", role: "Narrative Writer", linkedin:"https://www.linkedin.com/in/summergadsby/", image:Summer },
  { name: "Laura Quiambao", role: "Voice Artist", linkedin:"https://www.linkedin.com/in/laura-quiambao-3156a01a/", image:Laura },
  { name: "Booth Daniels", role: "Voice Artist", linkedin:"https://www.linkedin.com/in/boothdaniels/", image:Daniel},
  { name: "Anastasiya Kotava", role: "Environment Artist", linkedin:"https://www.linkedin.com/in/anastasiya-kotava-3dart/", image:Anastasiya },
  { name: "Johnny Delgado", role: "Level Designer", linkedin:"https://www.linkedin.com/in/johnny-delgado/", image:Johnny },
  { name: "Myreille Abaya", role: "Prompt Engineer", linkedin:"https://www.linkedin.com/in/myreille-abaya/", image:Myreille },
  { name: "Mitesh Gulecha", role: "Full Stack Developer", linkedin:"https://www.linkedin.com/in/mitesh-gulecha-5178381a9/", image:Mitesh },
];

export default function Team() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box sx={{
      padding: 4,
      textAlign: 'center',
    }}>
       <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h3" gutterBottom sx={{ color: '#52D5F2', marginBottom:"60px", fontWeight:"medium" }}>
          OUR TEAM
        </Typography>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 0.5 }}
      >
      <Typography variant="h4" gutterBottom sx={{ color: 'white', mb:5, fontWeight:"medium" }}>Leadership</Typography>      
        <Grid container spacing={4} justifyContent="center" sx={{ mb: 10 }}>
          {executiveTeam.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box sx={{ transform: 'scale(1.2)' }}> {/* Increase size */}
                <TeamMember {...member}  delay={index * 0.2} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 0.5 }}
      >
      <Grid container spacing={2} justifyContent="center" sx={{ mb: 10 }}>
        {leadershipTeam.map((member, index) => (
          <Grid item xs={12} sm={6} md={2} key={index}>
            <TeamMember {...member} delay={index * 0.1}/>
          </Grid>
        ))}
      </Grid>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 0.5 }}
      >
      <Typography variant="h4" gutterBottom sx={{ color: 'white', mb:10, fontWeight:"medium" }}>FSU Team Centered Around You</Typography>
      <Grid container spacing={2} justifyContent="center" sx={{mb:10}}>
        {contributors.map((contributor, index) => (
          <Grid item xs={12} sm={6} md={2} key={index}>
            <TeamMember {...contributor} delay={index * 0.1}/>
          </Grid>
        ))}
      </Grid>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 0.5 }}
      >
      <Typography variant="h4" gutterBottom sx={{  color: 'white', mb:10, fontWeight:"medium" }}>Our Advisors</Typography>
      <Grid container spacing={2} justifyContent="center" sx={{mb:10}}>
        {advisors.map((advisor, index) => (
          <Grid item xs={12} sm={6} md={2} key={index}>
            <TeamMember {...advisor} delay={index * 0.1}/>
          </Grid>
        ))}
      </Grid>
      </motion.div>
      
      
    </Box>
  );
}