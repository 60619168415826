import React, { useState, useEffect, memo } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import LoadingOverlay from "../loadingOverlay/loadingOverlay";

const MyUnityGame = memo(({showUnity,setShowUnity}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [startLoading, setStartLoading] = useState(false);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth - 30,
    height: window.innerHeight - 30,
  });

  const { unityProvider, isLoaded, loadingProgression, unload } = useUnityContext({
    loaderUrl: "https://game.futurestateuniversity.com/FSUServerBuild/WebGL/Build/WebGL.loader.js",
    dataUrl: "https://game.futurestateuniversity.com/FSUServerBuild/WebGL/Build/WebGL.data.gz",
    frameworkUrl: "https://game.futurestateuniversity.com/FSUServerBuild/WebGL/Build/WebGL.framework.js.gz",
    codeUrl: "https://game.futurestateuniversity.com/FSUServerBuild/WebGL/Build/WebGL.wasm.gz",
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth - 10,
        height: window.innerHeight - 10,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (startLoading) {
      const newProgress = loadingProgression * 100;
      if (newProgress !== progress) {
        setProgress(newProgress);
      }
      if (isLoaded && isLoading) {
        setIsLoading(false);
        setShowUnity(true);
      }
    }
    // Cleanup function to stop Unity when the component unmounts
    return () => {
      if (showUnity) {
        unload().then(() => {
          console.log("Unity game has been unloaded.");
        });
      }
    };
  }, [startLoading, isLoaded, loadingProgression, isLoading, progress, showUnity, setShowUnity, unload]);

  const handleEnter = () => {
    setStartLoading(true);
  };

  return (
    <div style={{ position: "absolute", width: `${dimensions.width}px`,
    height: `${dimensions.height}px`, maxWidth: "100vw", maxHeight: "100vh", display: "flex",
    justifyContent: "center",
    alignItems: "center", }}>
      {!showUnity && (
        <LoadingOverlay
          onEnter={handleEnter}
          isLoading={isLoading && startLoading}
          progress={progress}
        />
      )}
      {startLoading && (
        <Unity
          unityProvider={unityProvider}
          style={{
            visibility: showUnity ? "visible" : "hidden",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      )}
    </div>
  );
});

export default MyUnityGame;